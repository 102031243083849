html {
  font-size: 12px;
}
body {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0px;

  outline: none;
}

.mapContainer {
  height: 400px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 105%;
}

@font-face {
  font-family: "noto-sans-regular";
  src: url(assets/fonts/Noto_Sans/NotoSans-Regular.ttf);
}
